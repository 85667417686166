<template>
  <div class="gonggaoxq">
    <div class="header">
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item to="/logistics">物流服务</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex">
        <div class="xq">
          <el-descriptions class="margin-top"  :column="1" >
            <el-descriptions-item label="车辆类型">{{datas.carTypeName}}</el-descriptions-item>
            <el-descriptions-item label="可载重">{{datas.loadWeight}}吨</el-descriptions-item>
            <el-descriptions-item label="出发地">{{datas.departProvinceName}}/{{datas.departCityName}}/{{datas.departAreaName}}/{{datas.departAddress}}</el-descriptions-item>
            <el-descriptions-item label="到达地">{{datas.reachProvinceName}}/{{datas.reachCityName}}/{{datas.reachAreaName}}/{{datas.reachAddress}}</el-descriptions-item>
            <el-descriptions-item label="出发时间">{{datas.departTime}}</el-descriptions-item>
            <el-descriptions-item label="车辆图片" ><el-image style="width: 120px;height: 120px" :preview-src-list="[datas.carImage]" :src="datas.carImage"></el-image></el-descriptions-item>
            <el-descriptions-item label="联系人">{{ datas.contacts }}</el-descriptions-item>
            <el-descriptions-item label="司联系电话">{{ datas.phone }}</el-descriptions-item>
          </el-descriptions>
          <div class="button">
             <el-button type="primary" size="small" @click.stop.native="zixun()">车辆留言咨询</el-button>
            <shoucang  style="display: inline-block;margin-left: 20px" :articleType="datas.typeId==1?'logistics-release':'logistics-demand'" :articleId="$route.query.id"></shoucang>
          </div>
        </div>
      </div>
      <el-dialog title="发送" width="500px" append-to-body :visible.sync="dialogTableVisible">
        <el-form ref="form" :model="shen" label-width="100px" :rules="rules" :inline="true">
          <el-form-item label="咨询详情" prop="detail">
            <el-input rows="5" class="inputWidth mb8" type="textarea" v-model="shen.detail"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input   v-model="shen.contacts" class="inputWidth"></el-input>
          </el-form-item>
          <el-form-item label="手机号"   prop="phone">
            <el-input  v-model.number="shen.phone" class="inputWidth"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="margin: 20px 0 0 160px" @click="submitForm()">保存</el-button>
            <el-button @click="dialogTableVisible=!dialogTableVisible">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import fujian from '../common/fujian.vue'
import {logisticsdetail, warehousedetail, consultMessage} from "../../request/moudle/logistics";
import shoucang from "../../components/shoucang.vue";
export default {
  name: "gonggaoxq",
  components:{
    shoucang,
    fujian
  },
  data(){
    return {
      datas:'',
      dialogTableVisible:false,
      form:{},
      tenantId: '',
      shen:{},
      zxid:'',
      rules: {
        detail: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },{ type: 'number', message: '手机号必须为数字值'}
        ]
      },
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    submitForm(){
      let typeId = this.$route.query.typeId;
      if(typeId==1){
        this.shen.productType = "warehouse-release"
      }else {
        this.shen.productType = "warehouse-demand"
      }
      this.shen.productId = this.$route.query.id
      this.$api.logistics.consultMessage(this.shen).then((res)=>{
        this.dialogTableVisible=false
        this.$message({
          type: "success",
          message: "提交成功!"
        });
      })
    },

    zixun(){
      this.shen={}
      // this.zxid=item.id
      this.dialogTableVisible=true
    },

    loadxq(){
      this.$api.logistics.logisticsdetail({
        id:this.$route.query.id,
        typeId:this.$route.query.typeId
      }).then((res)=>{
          this.datas=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 80px;
}
.width300{
  width:300px ;
  margin-left:65px;
}
.inputWidth {
   width: 300px;
}
.mb8 {
   margin-bottom:8px;
}
.gonggaoxq{
    background: #F5F5F5;
  .fujian{
    margin-top: 50px;
    .as{
      line-height: 25px;
      color:  #409EFF;
    }
  }
  .header{
    height: 180px;
    background: url("../../assets/gxjy/gxjy.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 90px;
  }
  .content{
    width: 1200px;
    margin: 20px auto;
    .xq{
      background: #fff;
      margin-top: 20px;
      padding: 20px;
    }
    .title{
      color: #292929;
      text-align: center;
      font-weight: bolder;
      font-size: 20px;
    }
    .h1{
      line-height: 50px;
      text-align: center;
      span{
        margin-right: 50px;
      }
    }
  }
}
</style>